import React, { useEffect, useRef, useState } from 'react';
import './Smartphone.css';

interface SmartPhoneProps {
    isMobile: boolean;
}

export default function SmartPhone({ isMobile }: SmartPhoneProps) {

    const screenShot = require('../assets/images/image1.webp');
    const frame = require('../assets/images/frame.webp'); // 프레임 이미지 경로
    const header = require('../assets/images/header.webp');

    const containerRef = useRef<HTMLDivElement | null>(null);  // 스크롤을 위한 ref

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            // 컨테이너를 최하단으로 스크롤
            const observer = new IntersectionObserver(entries => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    container.scrollTop = container.scrollHeight - container.clientHeight;
                    container.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    observer.disconnect();
                }
            }, { threshold: 0.5 });

            observer.observe(container);

            return () => observer.disconnect();

            // container.scrollTop = container.scrollHeight - container.clientHeight;

            // // 일정 시간 후 최상단으로 부드럽게 스크롤
            // setTimeout(() => {
            //     container.scrollTo({
            //         top: 0,
            //         behavior: 'smooth'
            //     });
            // }, 500); // 페이지 로드 후 1초 후에 스크롤
        }
    }, []);


    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0,
        }}>
            <div style={{
                width: isMobile ? 255 : '412px', // 프레임 이미지의 실제 너비에 맞춥니다.
                height: isMobile ? 524 : '846px', // 프레임 이미지의 실제 높이에 맞춥니다.
                position: 'relative', // 내부 요소의 위치를 조정하기 위해 relative 설정
                borderRadius: '20px', // 프레임의 모서리를 둥글게 합니다. 모서리의 둥근 정도를 조절해주세요.
                overflow: 'hidden', // 프레임 바깥으로 내용이 나가지 않도록 합니다.
            }}>

                <img className='Smartphone-Header' src={header} alt='국시플래너 국가 고시 플래너 홈 화면의 헤더'
                    style={{
                        width: '90%',
                        position: 'absolute',
                        top: isMobile ? 7 : 17,
                        left: 20,
                        borderTopLeftRadius: isMobile ? 30 : 50,
                        borderTopRightRadius: isMobile ? 30 : 50,
                    }} />
                {/* 스크린샷을 보여줄 컨테이너 */}
                <div className='Smartphone-Container' ref={containerRef}
                    style={{
                        width: '94%', // 컨테이너 너비를 프레임 너비와 동일하게 설정
                        height: isMobile ? 'calc(100% - 54px)' : 'calc(100% - 120px)', // 컨테이너 높이를 프레임 높이와 동일하게 설정
                        overflowY: 'scroll', // 세로 스크롤 활성화
                        position: 'absolute', // 프레임 안에 정확하게 위치시키기 위해 absolute 설정
                        top: isMobile ? '60px' : '105px', // 프레임 상단에 맞춤
                        left: 20, // 프레임 왼쪽에 맞춤
                        borderBottomRightRadius: 50,
                        borderBottomLeftRadius: 50, // 스크린샷 컨테이너의 모서리를 둥글게 합니다.
                    }} >

                    <img src={screenShot} alt="국시플래너 국가고시 플래너 홈 화면"
                        style={{
                            width: '100%', // 각 스크린샷의 너비를 컨테이너에 맞춤
                            marginBottom: '10px', // 스크린샷 간의 간격
                        }} />
                </div>
                {/* 프레임 이미지 */}
                <img className='Smartphone-Frame' src={frame} alt="Smartphone component Frame" style={{
                    position: 'absolute',
                    width: '100%', // 프레임의 너비를 컨테이너 너비와 동일하게 설정
                    height: '100%', // 프레임의 높이를 컨테이너 높이와 동일하게 설정
                    top: 0, // 프레임을 컨테이너 상단에 맞춤
                    left: 0, // 프레임을 컨테이너 왼쪽에 맞춤
                    pointerEvents: 'none', // 프레임 위로 마우스 이벤트가 스크린샷 컨테이너
                    // 위로 전달되도록 합니다. 이를 통해 스크린샷 컨테이너 내에서 스크롤할 수 있습니다.
                }} />
            </div>
        </div>
    );
}