import { paletteLight } from '../styles/palette'
import SmartPhone from './SmartPhone';
import { useState } from 'react';
import Buttons from './Buttons';
import { useMediaQuery } from '@mui/material';

export default function Introduction() {
    const [isNurseClicked, setIsNurseClicked] = useState<boolean>(true);
    const [isNAClicked, setIsNAClicked] = useState<boolean>(false);

    const nurseToggleHandler = () => {
        if (isNurseClicked && !isNAClicked) { 
            setIsNurseClicked(false);
            setIsNAClicked(true);
        } else{
            setIsNurseClicked(true);
            setIsNAClicked(false);
        }
    };

    const NAToggleHandler = () => {
        if (!isNurseClicked && isNAClicked) { 
            setIsNurseClicked(true);
            setIsNAClicked(false);
        } else {
            setIsNurseClicked(false);
            setIsNAClicked(true);
        }
    };


    const isMobile = useMediaQuery(`(max-width: 900px)`);

    return (
        <div className="div-top"
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: isMobile ? 780 : 864,
                alignItems: 'center',
                justifyContent: isMobile ? 'center' : 'flex-end',
                backgroundColor: paletteLight.backgroundGrey,
                overflow: 'hidden',
                cursor: 'default',
            }}>
            <div className="IntroContainer"
                style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    alignItems: isMobile ? 'center' : 'flex-end',
                    position: 'relative',
                    width: isMobile ? 280 : 1024,
                    height: isMobile ? 571 : 800,
                }}>
                <div className="LeftHalf"
                    style={{
                        display: "flex",
                        height: isMobile ? 'auto' : 427,
                        flexDirection: 'column',
                        alignItems: isMobile ? 'center' : 'flex-start',
                        justifyContent: 'space-between',
                        paddingBottom: isMobile ? 18 : 187.6,
                    }}>
                    <div>
                        <div className="LabelContainer"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: isMobile ? 215 : 413,
                                justifyContent: isMobile ? 'center' : 'flex-start',
                                marginBottom: isMobile ? 25 : 65.54,
                                gap: isMobile ? 3 : 30,
                                fontFamily: 'Pretendard',
                                fontSize: isMobile ? 14 : 27,
                                fontWeight: 600,
                                letterSpacing: isMobile ? '-0.102px' : '-0.208px',
                                color: paletteLight.darkGrey100,
                                cursor: 'pointer',
                            }}>
                            <div className="NurseLabel"
                                style={{
                                    display: 'flex',
                                    width: isMobile ? 104 : 191,
                                    height: isMobile ? 36 : 64,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: isNurseClicked ? paletteLight.secondaryGreen : paletteLight.white,
                                    borderRadius: 30,
                                    border: `2px solid ${isNurseClicked ? paletteLight.primaryGreen : paletteLight.grey50}`,
                                    boxSizing: 'border-box',
                                }} onClick={nurseToggleHandler}>
                                간호사
                            </div>
                            <div className="NALabel"
                                style={{
                                    display: 'flex',
                                    width: isMobile ? 104 : 191,
                                    height: isMobile ? 36 : 64,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: !isNurseClicked ? paletteLight.secondaryGreen : paletteLight.white,
                                    borderRadius: 30,
                                    border: `2px solid ${!isNurseClicked ? paletteLight.primaryGreen : paletteLight.grey50}`,
                                    boxSizing: 'border-box',
                                }} onClick={NAToggleHandler}>
                                간호조무사
                            </div>
                        </div>
                        <div className="textContainer"
                            style={{
                                display: 'flex',
                                height: isMobile ? 52 : 123.384,
                                flexDirection: 'column',
                                alignItems: isMobile ? 'center' : 'flex-start',
                                justifyContent: 'space-between',
                                fontFamily: 'Pretendard',
                                fontSize: isMobile ? 22 : 45,
                                fontWeight: 700,
                                letterSpacing: isMobile ? '-.0132px' : '-1.147px'
                            }}>
                            <div>국가고시 준비 중이라면?</div>
                            <div style={{display: 'flex', flexDirection:"row"}}>
                                <div style={{ color: paletteLight.primaryGreen }}>국시플래너</div>
                                <div>로 공부하세요!</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: isMobile ? 'none' : 'flex' }}>
                        <Buttons isMobile={isMobile} />
                    </div>
                </div>
                <div className="RightHalf"
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: isMobile ? 'center' : 'flex-end',
                        width: isMobile ? 275 : '100%',
                        height: isMobile ? 436 : 'auto',
                        overflow: 'hidden',
                    }}>
                    <div style={{
                        marginBottom: isMobile ? '-34px' : '-234px',
                    }}>
                        <SmartPhone isMobile={isMobile} />
                    </div>
                </div>
            </div>
            <div className="Mobile-Buttons" style={{
                display: isMobile ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '40px',
            }}>
                <Buttons isMobile={isMobile} />
            </div>
        </div>
    )
}