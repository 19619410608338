import React from 'react'
import { privacyTerms } from "../store/termsOfUse"

const PrivacyTerms = () => {

    const splittedText = privacyTerms.split('\n');

    return(
        <div style={{marginLeft: 24, marginTop: 24}}>
            <div style={{fontSize: 24, color: "black", marginBottom: 12}}>개인정보 처리방침</div>
        {splittedText.map((text: string, idx: number) => (
            <div key={idx} style={{fontSize: 16, color: "black"}}>
                {text}
            </div>
        ))}
        </div>
    )
}

export default PrivacyTerms