import {paletteLight} from '../styles/palette';
import './Buttons.css';

interface ButtonsProps{
    isMobile: boolean;
    flexDirectionProp?: 'row' | 'column';
}

export default function Buttons({isMobile, flexDirectionProp='row'}:ButtonsProps) {

    const goToGooglePlay = () => {
        window.location.href = 'https://play.google.com/store/search?q=%EA%B5%AD%EC%8B%9C%ED%94%8C%EB%9E%98%EB%84%88&c=apps';
    };

    const goToAppStore = () => {
        window.location.href = 'https://apps.apple.com/kr/app/%EA%B5%AD%EC%8B%9C%ED%94%8C%EB%9E%98%EB%84%88/id6471399642';
    }

    const googleplay = require('../assets/logo/googleplay.webp');
    const appstore = require('../assets/logo/appstore.webp');

    return(
    <div className="ButtonContainer"
                        style={{
                            display: 'flex',
                            width: isMobile ? 280 : 'auto',
                            flexDirection: flexDirectionProp,
                            justifyContent: 'space-between',
                            gap: isMobile ? 8 : 20,
                            fontFamily: 'Pretendard',
                            fontSize: isMobile ? 14 : 21.118,
                            fontWeight: 500,
                            letterSpacing: isMobile? '-0.127px' : '-0.201px',
                            color: paletteLight.white,
                        }}>
                        <div className='Google-Play' onClick={goToGooglePlay}
                            style={{
                                display: 'flex',
                                width: isMobile? 135 : 210,
                                height: isMobile? 44 : 65.536,
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: isMobile ? 8.45 : 13.396,
                                borderRadius: 6.698,
                                cursor: "default",
                            }}>
                            <img src={googleplay} width={isMobile ? '13px' : '22.161px'} height={isMobile ? '15' : '24.715px'} alt="Google Play Market Logo"/>
                            Google Play
                        </div>
                        <div className='App-Store'onClick={goToAppStore}
                            style={{
                                display: 'flex',
                                width: isMobile? 135 : 210,
                                height: isMobile? 44 : 65.536,
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: isMobile ? 8.45 : 13.396,
                                borderRadius: 6.698,
                                cursor: "default",
                            }}>
                            <img src={appstore} width={isMobile ? '13px' : '22.161px'} height={isMobile ? '15' : '24.715px'} alt="App Store Logo"/>
                            App Store
                        </div>
                    </div>
    )
}