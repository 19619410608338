import { useEffect, useRef, useState } from 'react';
import { paletteLight } from '../styles/palette';
import image2 from "../assets/images/image2.webp";
import image3 from "../assets/images/image3.webp";
import './NurseAssistant.css';
import { useMediaQuery } from '@mui/material';

export default function NurseAssistant() {

    const isMobile = useMediaQuery('(max-width: 900px)');
    const firstImageRef = useRef<HTMLDivElement | null>(null);
    const secondImageRef = useRef<HTMLDivElement | null>(null);
    const [firstImageVisible, setFirstImageVisible] = useState(false);
    const [secondImageVisible, setSecondImageVisible] = useState(false);

    useEffect(() => {
        const options = { threshold: 1.0 };
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.target === firstImageRef.current) {
                    setFirstImageVisible(entry.isIntersecting);
                } else if (entry.target === secondImageRef.current) {
                    setSecondImageVisible(entry.isIntersecting);
                }
            });
        }, options);

        if (firstImageRef.current) {
            observer.observe(firstImageRef.current);
        }
        if (secondImageRef.current) {
            observer.observe(secondImageRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (firstImageVisible && firstImageRef.current) {
            firstImageRef.current.scrollTo({
                top: firstImageRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [firstImageVisible]);

    useEffect(() => {
        if (firstImageVisible && secondImageVisible && secondImageRef.current) {
            const timeoutId = setTimeout(() => {
                secondImageRef.current?.scrollTo({
                    top: secondImageRef.current.scrollHeight,
                    behavior: 'smooth'
                });
            }, 100);  // 첫 번째 이미지 스크롤 완료 후 약간의 딜레이를 주고 두 번째 이미지 스크롤 시작

            return () => clearTimeout(timeoutId);
        }
    }, [firstImageVisible, secondImageVisible]);

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: 864,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: paletteLight.backgroundGrey,
        }}>
            <div style={{
                display: 'flex',
                width: isMobile ? 800 : 1024,
                height: '100%',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: isMobile ? 'space-between' : 'space-between',
                alignItems: isMobile ? 'center' : 'flex-end'
            }}>
                <div className='LeftHalf'
                    style={{
                        display: 'flex',
                        width: isMobile ? 260 : '100%',
                        height: '100%',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        fontFamily: 'Pretendard',
                        gap: isMobile ? 14 : 40,
                    }}>
                    <div className="NALabel"
                        style={{
                            display: 'flex',
                            width: isMobile ? 104 : 191,
                            height: isMobile ? 36 : 64,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: paletteLight.white,
                            borderRadius: 30,
                            border: `2px solid ${paletteLight.primaryGreen}`,
                            boxSizing: 'border-box',
                            fontSize: isMobile ? 15 : 27,
                            fontWeight: 600,
                            letterSpacing: isMobile ? '-0.1px' : '-0.208px',
                            color: paletteLight.darkGrey100,
                        }}>
                        간호조무사
                    </div>
                    <div className='TextContainer'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 32,
                        }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: isMobile ? 30 : 43,
                            fontWeight: 700,
                            lineHeight: "141.395%",
                            letterSpacing: '-1.68px'
                        }}>
                            <div>나에게 딱 맞는</div>
                            <div>공부 계획과</div>
                            <div>성적 분석까지</div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: isMobile ? 18 : 27,
                            fontWeight: 500,
                            lineHeight: "140%",
                            letterSpacing: "-0.72px",
                            color: paletteLight.grey250,
                        }}>
                            <div>시험 날짜와 나의 성적을 분석해서</div>
                            <div>맞춤 공부 계획을 설정해줘요!</div>
                        </div>
                    </div>
                </div>
                <div className='RightHalf'
                    style={{
                        display: 'flex',
                        width: isMobile ? 260 : 'auto',
                        height: isMobile ? 535 : 864,
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: isMobile ? 20 : 0,
                    }}>
                    <div className='image-container' ref={firstImageRef} style={{
                        width: isMobile ? '100%' : 404,
                        height: isMobile ? 206 : 320,
                        overflowY: 'auto',
                        borderRadius: '0px 0px 17.6px 17.6px',
                        boxShadow: '0px 4.9px 19.7px 0px rgba(0, 0, 0, 0.05)',
                    }}>
                        <img src={image2} alt='간호조무사 전용 국가 고시 공부 플랜 설정 화면' width={isMobile ? '260px' : '404px'} height='auto' />
                    </div>
                    <div className='image-container' ref={secondImageRef} style={{
                        width: isMobile ? '100%' : 404,
                        height: isMobile ? 300 : 480,
                        overflowY: 'auto',
                        borderRadius: '17.6px 17.6px 0px 0px',
                        boxShadow: '0px 4.9px 19.7px 0px rgba(0, 0, 0, 0.05)',
                    }}>
                        <img src={image3} alt='간호조무사 전용 국가 고시 예상 점수, 진도, 성적 향상 트래커 화면' width={isMobile ? '260px' : '404px'} height='auto' />
                    </div>
                </div>
            </div>

        </div>
    )
}
