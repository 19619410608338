import { useEffect, useRef, useState } from 'react';
import { paletteLight } from "../styles/palette";
import problems from "../assets/images/problems.webp";
import subject from "../assets/images/subject.webp";
import { useMediaQuery } from "@mui/material";
import "./Nurse.css";

export default function Nurse() {
    const isMobile = useMediaQuery('(max-width: 900px)');

    return (
        <div className="div-top"
            style={{
                display: 'flex',
                width: '100%',
                height: isMobile ? 830 : 864,
                justifyContent: 'center',
                backgroundColor: paletteLight.secondaryGreen,
            }}>
            <div style={{
                display: 'flex',
                width: isMobile ? 260 : 1024,
                height: isMobile ? 830 : 864,
                flexDirection: isMobile ? 'column-reverse' : 'row',
                justifyContent: isMobile ? 'flex-start' : 'center',
                alignItems: isMobile ? 'center' : 'flex-end',
                gap: isMobile ? 26 : 0,
            }}>
                <div className="LeftHalf"
                    style={{
                        display: 'flex',
                        width: isMobile ? 260 : 'auto',
                        height: isMobile ? 578 : '100%',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'space-between',
                    }}>
                    {/* <Statistics isMobile={isMobile}/> */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        borderRadius: '0px 0px 17.6px 17.6px',
                        boxShadow: '0px 4.923px 19.694px 0px rgba(0,0,0,0.05)'
                    }}>
                        <img src={problems} width={isMobile ? "260px" : "404px"} height={isMobile ? "235px" : "360px"} alt="간호사 국가 고시 문제 선택 화면" />
                    </div>
                    <div className='image-container' style={{
                        width: isMobile ? '100%' : 404,
                        height: isMobile ? 302 : 440,
                        overflowY: 'auto',
                        boxShadow: '0px 4.923px 19.694px 0px rgba(0,0,0,0.05)',
                        borderRadius: '17.6px 17.6px 0px 0px',
                    }}>
                        <img src={subject} width={isMobile ? "260px" : "404px"} height={"auto"} alt="간호사 국가 고시 전용 과목 선택 화면" />
                    </div>
                </div>
                <div className="RightHalf"
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: isMobile ? 176 : '100%',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: isMobile ? 'flex-start' : 'flex-end',
                        gap: isMobile ? 15 : 40,
                    }}>
                    <div className="NurseLabel"
                        style={{
                            display: 'flex',
                            width: isMobile ? 104 : 191,
                            height: isMobile ? 35 : 64,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: paletteLight.white,
                            borderRadius: 30,
                            border: `2px solid ${paletteLight.primaryGreen}`,
                            boxSizing: 'border-box',
                            fontFamily: 'Pretendard',
                            fontSize: isMobile ? 15 : 27,
                            fontWeight: 600,
                            letterSpacing: isMobile ? '-0.1px' : '-0.208px',
                        }}>
                        간호사
                    </div>
                    <div className='TextContainer'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontFamily: 'Pretendard',
                            gap: isMobile ? 14 : 32,
                        }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: isMobile ? 'flex-start' : 'flex-end',
                            fontSize: isMobile ? 24 : 45,
                            fontWeight: 700,
                            letterSpacing: isMobile ? '-0.4px' : '-1.147px',
                            lineHeight: isMobile ? '141%' : '148.837%',
                        }}>
                            <div>틀릴 만한 문제만</div>
                            <div>AI가 쏙쏙 짚어</div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: isMobile ? 'flex-start' : 'flex-end',
                            fontSize: isMobile ? 14 : 24,
                            fontWeight: 500,
                            color: paletteLight.grey250,
                            lineHeight: isMobile ? '142%' : '140%',
                            letterSpacing: isMobile ? '-0.3px' : '-0.72px',
                        }}>
                            <div>원하는 방식으로 공부할 수 있도록</div>
                            <div>다양한 학습 방식을 제공해줘요!</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}