import Header from '../components/Header';
import Introduction from '../components/Introduction';
import Nurse from '../components/Nurse';
import NurseAssistant from '../components/NurseAssistant';
import Footer from '../components/Footer';


function GuksiPlannerLandingPage() {

  return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Header />
        <Introduction />
        <Nurse />
        <NurseAssistant />
        <Footer />
      </div>
  );
}

export default GuksiPlannerLandingPage;
