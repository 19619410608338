import { paletteLight } from "../styles/palette";
import AppLogo from "../assets/logo/AppLogo.webp"
import Buttons from "./Buttons";
import { useMediaQuery } from "@mui/material";

export default function Footer() {
    const isMobile = useMediaQuery(`(max-width: 900px)`)


    return (
        <div className='div-top' 
            style={{
            display: 'flex',
            width: '100%',
            height: isMobile ? 260 : 864,
            justifyContent: 'center',
            backgroundColor: paletteLight.white,
        }}>
            <div className="div-second-top" 
                style={{
                display: 'flex',
                flexDirection: 'column',
                width: isMobile ? 260 : 1024,
                height: isMobile ? '100%' : 864,
                justifyContent: isMobile ? 'center' : 'center',
                alignItems: 'flex-start',
                gap: 18,
            }}>
                <div style={{
                    display: isMobile ? 'flex' : 'none',
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: '100%',
                    letterSpacing: '-0.9%',
                }}>
                    <div>국시플래너 앱 다운로드</div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'row' : 'column',
                        alignItems: isMobile ? 'center' : 'flex-start',
                        justifyContent: 'center',
                        fontFamily: 'Pretendard',
                        gap: isMobile ? 24 : 0,
                    }}>
                    <img src={AppLogo} alt="AppLogo" width={isMobile ? "96" : "162px"} height={isMobile ? "96" : "162px"}
                        style={{
                            boxShadow: '0px 0px 9.6px 0px rgba(0,0,0,0.15)',
                            borderRadius: isMobile ? 14 : 38,
                        }} />

                    <div style={{
                        display: isMobile ? 'none' : 'flex',
                        fontSize: 28,
                        fontWeight: 600,
                        lineHeight: '150%',
                        letterSpacing: '-0.9%',
                        marginTop: 40,
                        marginBottom: 20,
                    }}>
                        <div>국시플래너 앱 다운로드</div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontSize: 21.118,
                        fontWeight: 500,
                        letterSpacing: '-0.201px',
                        color: paletteLight.white,
                    }}>
                        <Buttons isMobile={isMobile} flexDirectionProp={isMobile ? 'column' : 'row'} />
                    </div>
                </div>
            </div>
        </div>
    )
}