import React from 'react';
import { lightTheme } from './styles/palette';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom'
import GuksiPlannerLandingPage from './pages/guksiplannerLandingPage';
import PrivacyTerms from './pages/privacyTerms';


function App() {

  console.log(process.env.PUBLIC_URL)

  return (
    <ThemeProvider theme={lightTheme}>
      <HashRouter>
      <Routes>
        <Route path="/" element={<GuksiPlannerLandingPage />} />
        <Route path="/privacyTerms" element={<PrivacyTerms />} />
      </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
