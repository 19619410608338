import { createTheme } from '@mui/material/styles';

export const paletteLight = {

    surface: "#FBFAF2",
    onSurface: "#000000",
    onSurfaceVariant: "#45483D",
    surfaceContainerHigh: "#E9E8E1",
    surfaceContainerLowest: "#fff",
  
    outline: "#75786C",
    outlineVariant: "#C5C8B9",
  
    white: '#FFFFFF',
    black: '#1D1D1D',
    surfaceContainer: '#E6E8EC',

    grey20: "#F7F7F8",
    grey50: '#E6E8EC',
    grey100: '#D7D9DE',
    grey150: '#C6C8CD',
    grey200: '#BCBEC3',
    grey250: '#A0A2A7',
    darkGrey100: '#3B3E46',
    secondaryGrey:'#CDD0D5',
    backgroundGrey: '#F6F7F9',

    primaryGreen: '#00C478',
    secondaryGreen: '#E4F9F0',
    containerGreen: '#06CB7E',
    onPrimaryGreen: "#FFFFFF",

    errorRed: '#FF5555',
    errorSecondaryRed: '#FFEEEE',

    secondaryOutline: "#D7D9DE",
    disableBorder: "#F2F3F3",
    disableBackground: "#F7F7F8",

  };

  export const paletteDark = {
    // * 예전 버전 ( 추후 삭제 해야함 )
    surface: "#FBFAF2",
    onSurface: "#000000",
    onSurfaceVariant: "#45483D",
    surfaceContainerHigh: "#E9E8E1",
    surfaceContainerLowest: "#fff",
  
    outline: "#75786C",
    outlineVariant: "#C5C8B9",
    surfaceContainer: '#E6E8EC',
  
    // * NA 에서 새로 추가된 항목
    white: '#1C1C22',
    black: '#E2E2E2',

    grey20: "#25272E",
    grey50: '#2A2C33',
    grey100: '#4D525A',
    grey150: '#515660',
    grey200: '#5B606A',
    grey250: '#707680',
    backgroundGrey: '#17171B',

    primaryGreen: '#00B66F',
    secondaryGreen: '#0E412B',
    onPrimaryGreen: "#E2E2E2",

    errorRed: '#CA3E3E',
    errorSecondaryRed: '#4B2121',
  };
  
  export const paletteDefault = {
    neutralVariant80: "",
    neutralVariant95: "",
  };
  
  export const palette = {
    'true' : paletteDark,
    'false' : paletteLight,
  }

  export const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#00C478',
      },
      secondary:{
        main:'#E4F9F0',
      },
      text: {
        primary: '#1D1D1D',
        secondary: '#FFFFFF',
      },
      background: {
        default: paletteLight.surface,
      },
    },
  });